// Imports
import React from 'react'
import { Link } from 'gatsby'

// Footer component
const Footer = () => (
  <footer className="footer">
    <div className="container">
      

      
    </div>
  </footer>
)

export default Footer